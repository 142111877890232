import { axios } from "@Root/API";
// INPUT DATA LISTS

export const getCountryByName = countryName => {
  return axios.get(`countries/find/${countryName}`);
};

export const getTitles = () => {
  return axios.get("titles");
};

export const getGenders = () => {
  return axios.get("genders");
};

export const getCountries = () => {
  return axios.get("countries");
};

export const getRelationships = contactType => {
  return axios.get(`contacts/${contactType}/relationships`);
};

export const getPersons = params => {
  return axios.get("people", {
    params
  });
};
export const getOrganisations = params => {
  return axios.get("organisations", {
    params
  });
};
